import React, { Component, Fragment } from "react";
import { ContactContainer, Container, Title, Form, Input, Textarea, Button, Img } from "./ContactStyles";
import engineer from "../../images/engineer.png";

class Contact extends Component {
  render() {
    return (
      <Fragment>
        <ContactContainer>
          <Container>
            <Title>Contact Us</Title>
            <Form action="https://formspree.io/mlzqravm" method="POST">
              <Input type="text" name="name" placeholder="Name" required />
              <Input type="text" name="phone" placeholder="Phone" required />
              <Input type="email" name="email" placeholder="Email" required />
              <Input type="text" name="city" placeholder="City" required />
              <Textarea
                required
                name="message"
                id="message"
                cols="30"
                rows="10"
                placeholder="Leave us your message or request for your product and we will respond as soon as possible."
              />
              <Button type="submit">Send</Button>
            </Form>
          </Container>
          <Img src={engineer} />
        </ContactContainer>
      </Fragment>
    );
  }
}

export default Contact;
