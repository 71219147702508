import React, { Component } from "react";
import magnifyingGlass from "../../images/magnifying-glass.png";
import wheel from "../../images/wheel.png";
import { ServiceContainer, MainContainer, Container, Img, Title, Description } from "./ServicesStyles";

class Services extends Component {
  render() {
    return (
      <ServiceContainer>
        <MainContainer>
          <Container>
            <Img backgroundImage={wheel} />
            <Title>Wheels sale</Title>
            <Description>
              We distribute the bests brands of wheels for forklifts in the market, improving the performance of your equipments.
            </Description>
          </Container>
          <Container>
            <Img backgroundImage={magnifyingGlass} />
            <Title>Diagnosis and professional advise</Title>
            <Description>
              We attend preventively and corrective the operation of our products, guaranteeing their best performance.
            </Description>
          </Container>
        </MainContainer>
      </ServiceContainer>
    );
  }
}

export default Services;
