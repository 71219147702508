import React, { Component } from "react";
import Slider from "react-slick";
import arrow from "../../images/arrow.png";
import { MainContainer, Slide, Container, Title, Description, Button, Arrow, Label } from "./HomePageHeroStyles";
import hero1 from "../../images/hero-1.jpg";
import hero2 from "../../images/hero-2.jpg";
import hero3 from "../../images/hero-3.jpg";

class Hero extends Component {
  render() {
    const settings = {
      arrows: false,
      autoplay: true,
      dots: true,
      infinite: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      speed: 500
    };
    return (
      <MainContainer>
        <Slider {...settings}>
          <Slide backgroundImage={hero1}>
            <Container>
              <Title maxWidth="520px">We raise your company's goals</Title>
              <Description>
                Know our wide range of available products and discover how
                <Label> we can grow your business according to your particular needs</Label>.
              </Description>
              <Button to="/#about-us">Discover them</Button>
            </Container>
          </Slide>
          <Slide backgroundImage={hero2}>
            <Container>
              <Title maxWidth="520px">We work with the best brands</Title>
              <Description>
                All our products have the guarantee and confidence of <Label>the best brands in the market</Label>.
              </Description>
              <Button to="/wheels">Our wheels</Button>
            </Container>
          </Slide>
          <Slide backgroundImage={hero3}>
            <Container>
              <Title maxWidth="730px">We have the best delivery times in the market</Title>
              <Description>
                We recognize the value of your time, so our personalized service and delivery allows you to
                <Label> obtain your products quickly and safely</Label>.
              </Description>
              <Button to="/#diferentials">what sets us apart</Button>
            </Container>
          </Slide>
        </Slider>
        <Arrow>
          <img src={arrow} alt="Arrow" />
        </Arrow>
      </MainContainer>
    );
  }
}

export default Hero;
