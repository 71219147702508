import React, { Component } from "react";
import feature1 from "../../images/feature1.png";
import feature2 from "../../images/feature2.png";
import feature3 from "../../images/feature3.png";
import feature4 from "../../images/feature4.png";
import {
  MainContainer,
  Container,
  MainTitle,
  TitleDescription,
  FeatureContainer,
  Feature,
  Image,
  Title,
  Description
} from "./FeaturesStyles";

class Features extends Component {
  render() {
    return (
      <MainContainer>
        <Container>
          <MainTitle>
            What sets us <strong>apart</strong>
          </MainTitle>
          <TitleDescription>
            In addition to providing the best services and products on the market, we take care of every detail of your purchase process.
            That is why we also advise and do personal follow-up of your orders and needs.
          </TitleDescription>
          <FeatureContainer>
            <Feature>
              <Image src={feature1} />
              <div>
                <Title>Delivery times</Title>
                <Description>
                  Our delivery times are unmatched. We make the shipment personally ensuring that they arrive at the right time.
                </Description>
              </div>
            </Feature>
            <Feature>
              <Image src={feature2} />
              <div>
                <Title>Unique products in the market</Title>
                <Description>
                  Our products of all ranges cover the spectrum of your needs and guarantee the solution of your particular needs.
                </Description>
              </div>
            </Feature>
            <Feature>
              <Image src={feature3} />
              <div>
                <Title>Support and maintenance</Title>
                <Description>
                  We serve subsequent needs that are presented to our customers. We advise and guarantee the complete experience.
                </Description>
              </div>
            </Feature>
            <Feature>
              <Image src={feature4} />
              <div>
                <Title>Service and attention</Title>
                <Description>
                  We treat each of our clients in a personalized way, that is why we are aware of every detail of the process.
                </Description>
              </div>
            </Feature>
          </FeatureContainer>
        </Container>
      </MainContainer>
    );
  }
}

export default Features;
