import React, { Component, Fragment } from "react";
import Hero from "../Hero/Hero";
import WheelsRecommended from "../WheelsRecommended/WheelsRecommended";
import WheelsOthers from "../WheelsOthers/WheelsOthers";
import WheelsTypes from "../WheelsTypes/WheelsTypes";
import { Title, TextLight, Description, TextBold } from "./WheelsStyles";

class Wheels extends Component {
  render() {
    return (
      <Fragment>
        <Hero
          title={
            <Title>
              <TextLight>Our</TextLight> wheels
            </Title>
          }
          description={
            <Description>
              We distribute wheels of the most recognized brands in the market, guaranteeing the quality of your products and their delivery
              just when you need it. Compare your characteristics, find the ideal one for you{" "}
              <TextBold>or contact us to advise or make your purchase</TextBold>.
            </Description>
          }
        />
        <WheelsRecommended />
        <WheelsOthers />
        <WheelsTypes />
      </Fragment>
    );
  }
}

export default Wheels;
