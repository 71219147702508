import React, { Component } from "react";
import {
  Arrow,
  Bold,
  Card,
  Container,
  Description,
  Image,
  ImageItem,
  MainContainer,
  MoreInfo,
  Specs,
  SpecsItem,
  SpecsTitle,
  SubTitle,
  TextAlignCenter,
  Title
} from "./WheelsOthersStyles";

export default class WheelsOthers extends Component {
  state = {
    selectedCard: ""
  };

  selectCard = value => {
    this.setState(({ selectedCard }) => ({ selectedCard: selectedCard === value ? "" : value }));
  };

  render() {
    const { selectedCard } = this.state;

    return (
      <MainContainer>
        <Title>
          Get to know our <Bold>other options</Bold>
        </Title>
        <Container>
          <Card isSelected={selectedCard === "sps"} height="809px">
            <TextAlignCenter>
              <Image src={require("../../images/wheels-3.png")} alt="Superthane" />
            </TextAlignCenter>
            <div>
              <SubTitle color="#ED3833">SPS</SubTitle>
              <Description>The next generation of Soft Polyurethane Pressing Tires.</Description>
            </div>
            <MoreInfo>
              <Specs>
                <SpecsTitle>Features</SpecsTitle>
                <SpecsItem>- Ideal for cava freezing</SpecsItem>
                <SpecsItem>- Softest ride</SpecsItem>
                <SpecsItem>- Rough floors</SpecsItem>
                <SpecsItem>- Loading docks</SpecsItem>
              </Specs>
              <ImageItem src={require("../../images/spec-2.png")} alt="A95" />
            </MoreInfo>
            <Arrow
              isSelected={selectedCard === "sps"}
              src={require("../../images/arrow.svg")}
              alt="Flecha"
              onClick={() => this.selectCard("sps")}
            />
          </Card>
          <Card isSelected={selectedCard === "hyload"} height="790px">
            <div>
              <TextAlignCenter>
                <Image src={require("../../images/wheels-4.png")} alt="Superthane" />
              </TextAlignCenter>
              <div>
                <SubTitle color="#A4C239">Hyload</SubTitle>
                <Description>The Ultimate Solution</Description>
              </div>
            </div>
            <MoreInfo>
              <Specs>
                <SpecsTitle>Features</SpecsTitle>
                <SpecsItem>- Long runs</SpecsItem>
                <SpecsItem>- Heavy Loads</SpecsItem>
                <SpecsItem>- High speeds</SpecsItem>
                <SpecsItem>- Order picking</SpecsItem>
              </Specs>
              <ImageItem src={require("../../images/spec-1.png")} alt="A95" />
            </MoreInfo>
            <Arrow
              isSelected={selectedCard === "hyload"}
              src={require("../../images/arrow.svg")}
              alt="Flecha"
              onClick={() => this.selectCard("hyload")}
            />
          </Card>
          <Card isSelected={selectedCard === "vulkollan"} height="790px">
            <div>
              <TextAlignCenter>
                <Image src={require("../../images/wheels-5.png")} alt="Superthane" />
              </TextAlignCenter>
              <div>
                <SubTitle color="#E2C242">Vulkollan</SubTitle>
                <Description>Unparallaled Durability</Description>
              </div>
            </div>
            <MoreInfo>
              <Specs>
                <SpecsTitle>Features</SpecsTitle>
                <SpecsItem>- Ultra high load capacity</SpecsItem>
                <SpecsItem>- Extreme resistance to chunking and tearing.</SpecsItem>
                <SpecsItem>- Very low rolling resistance.</SpecsItem>
              </Specs>
              <ImageItem src={require("../../images/spec-3.png")} alt="A95" />
            </MoreInfo>
            <Arrow
              isSelected={selectedCard === "vulkollan"}
              src={require("../../images/arrow.svg")}
              alt="Flecha"
              onClick={() => this.selectCard("vulkollan")}
            />
          </Card>
        </Container>
      </MainContainer>
    );
  }
}
