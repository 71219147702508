import React, { Component, Fragment } from "react";
import { Container, InnerContainer, List, ListNav, ListItem, Image, Label, LogoFooter, StyledLink } from "./FooterStyles";

class Footer extends Component {
  render() {
    return (
      <Fragment>
        <Container>
          <InnerContainer>
            <ListNav>
              <ListItem>
                <StyledLink to="/">Home</StyledLink>
              </ListItem>
              <ListItem>
                <StyledLink to="/#about-us">About us</StyledLink>
              </ListItem>
              <ListItem>
                <StyledLink to="/#diferentials">Diferentials</StyledLink>
              </ListItem>
              <ListItem>
                <StyledLink to="/#contact">Get in touch</StyledLink>
              </ListItem>
              <ListItem>
                <StyledLink to="/wheels">Wheels</StyledLink>
              </ListItem>
            </ListNav>
            <List>
              <ListItem marginBottom="24px">
                <Image src={require("../../images/phone.svg")} alt="Telefono" />
                <Label>(+1) 321 4260673 - (+1) 954 305 7398</Label>
              </ListItem>
              <ListItem marginBottom="24px">
                <Image src={require("../../images/envelop.svg")} alt="Correo" />
                <Label>info@lsjm2.com</Label>
              </ListItem>
              <ListItem marginBottom="24px">
                <Image src={require("../../images/landmark.svg")} alt="Ubicación" />
                <Label>Miami, USA</Label>
              </ListItem>
            </List>
            <List>
              <ListItem>
                <a href="https://www.facebook.com/Soluciones-Logisticas-JM2-323220237727522/" rel="noopener noreferrer" target="_blank">
                  <Image src={require("../../images/facebook.svg")} alt="Facebook" />
                </a>
                <a href="https://www.instagram.com/solucioneslogisticasjm2/" target="_blank" rel="noopener noreferrer">
                  <Image src={require("../../images/instagram.svg")} alt="Instagram" />
                </a>
              </ListItem>
            </List>
          </InnerContainer>
          <LogoFooter src={require("../../images/logo-footer.png")} alt="Logo" />
        </Container>
      </Fragment>
    );
  }
}

export default Footer;
