import styled from "styled-components";
import { device } from '../../BreakPoints';

export const HomeContainer = styled.div`
  background-color: #f3f3f3;
  padding-top: 120px;
`;

export const Container = styled.div`
  background-color: #ffffff;
  border-top: transparent;
  color: #656464;
  font-family: "Montserrat", sans-serif;
  padding: 30px 0 45px;
  text-align: center;
`;

export const Title = styled.h1`
  font-size: 37px;
  font-weight: 300;
`;

export const Description = styled.p`
  font-weight: 300;
  margin: 0 auto;
  max-width: 960px;
  padding: 0 20px;

  @media ${device.tablet} {
    max-width: 700px;
  }
`;
