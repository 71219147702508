import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import scrollIntoView from "scroll-into-view";
import Contact from "../Contact/Contact";
import Features from "../Features/Features";
import HomePageHero from "../HomePageHero/HomePageHero";
import Service from "../Services/Services";
import { HomeContainer, Container, Title, Description } from "./HomeStyles";

let aboutUs;
let features;
let contact;

class Home extends Component {
  state = {};

  static scrollToSection = hash => {
    if (hash) {
      let element;
      switch (hash) {
        case "#about-us":
          element = aboutUs;
          break;
        case "#diferentials":
          element = features;
          break;
        case "#contact":
          element = contact;
          break;
        default:
          break;
      }
      scrollIntoView(element, { align: { top: 0, topOffset: 100 } });
    }
  };

  componentDidMount() {
    Home.scrollToSection(this.props.location.hash);
  }

  static getDerivedStateFromProps({ location }) {
    Home.scrollToSection(location.hash);
    return {};
  }

  render() {
    return (
      <HomeContainer>
        <HomePageHero />
        <Container ref={ref => (aboutUs = ref)}>
          <Title>
            About <strong>us</strong>
          </Title>
          <Description>
            We support the logistics management of your business with integrated solutions for storage centers through the sale of wheels
            that will increase the productivity of your operations.
          </Description>
        </Container>
        <Service />
        <div ref={ref => (features = ref)}>
          <Features />
        </div>
        <div ref={ref => (contact = ref)}>
          <Contact />
        </div>
      </HomeContainer>
    );
  }
}

export default withRouter(Home);
